export default {
  welcome: {
    button: "Enter",
    settings: "Settings",
    update: "Last update",
    guest: "Guest",
    connect: "Connect",
    disconnect: "Disconnect",
    welcome: "Welcome",
    authenticating: "Authenticating",
    isAuthenticated: "Redirecting to authentication",
    mapboxError: "No Mapbox key defined. Please add one in .env file.",
    webglError: "WebGL is not activated. Please configure your web browser.",
    use_intro: "To start using Kite, click on a tab at the top of this page.",
    help: {
      button: "Step by step guides",
      tooltip: "Open step by step guides and documentation"
    }
  },
  upload: {
    success: "Your uplaod was successful",
    failed: "Upload error"
  },
  visualisation: {
    loading: "Loading"
  },
  basic_dialogs: {
    cancel: "Cancel",
    clear: "Clear",
    close: "Close",
    reset: "Reset",
    validate: "Validate",
    validate_and_save: "Validate and save",
    start: "Start",
    done: "Done",
    add: "Add",
    open: "Open",
    execute: "Execute",
    copyof: "Copy of",
    delete: "Delete",
    copy: "Copy",
    download: "Download data",
    draw: "Draw",
    import: "Import",
    export: "Export",
    loading: "Loading",
    remove: "Remove",
    modify: "Modify layer style",
    save: "Save in project",
    load: "Load",
    confirm_delete: "Do you really want to delete this object ?",
    share: "Share",
    undefined: "Undefined",
    filename: "Filename",
    format: "Format",
    confirm_delete_map: "Do you really want to delete this map ?",
    no_data: "No data",
    zoom: "Zoom",
    glossary: "Glossary",
    selected: "{nb} selected",
    rename: "Rename",
    open_in_new_tab: "Open in a new tab",
    select_all: "Select all"
  },
  basic_transport: {
    direction: "Direction",
    directions: {
      departure: "Departure",
      arrival: "Arrival",
      0: "One way",
      1: "Back"
    },
    mode: "Mode",
    speed: "Speed",
    modes: {
      car: "Car",
      pedestrian: "Pedestrian",
      bike: "Bike",
      electrical_bike: "Electrical bike",
      public_transport: "Public transport"
    },
    route_types: {
      0: "Tramway",
      1: "Subway",
      2: "Rail",
      3: "Bus",
      4: "Ferry",
      5: "Cable tramway",
      6: "Suspended cableway",
      7: "Funicular",
      11: "Trolley",
      12: "Monorail"
    },
    day_type: "Day type",
    day_types: {
      complete_day_type: "{weekday} - {period}",
      LMaJV: "MoTuThuFri",
      Me: "Wednesday",
      Sam: "Saturday",
      DimF: "Sunda Public holidays",
      HV: "Outside holidays",
      VAC: "Holidays",
      ETE: "Summer"
    }
  },
  basic_headers: {
    name: "Name",
    type: "Type",
    date: "Date",
    actions: "Actions",
    tools: "Tools",
    displayed: "Displayed",
    source: "Source",
    theme: "Theme",
    creator: "Author",
    label: "Label",
    value: "Value"
  },
  units: {
    sec: "seconds",
    min: "minutes",
    hours: "hours",
    m: "meters",
    km: "kilometers",
    km_per_hour: "km/h"
  },
  schema: {
    download: "Download schema",
    type: "must be of type",
    required: "Missing required property",
    constraint: "must be"
  },
  boolean: {
    true: "Yes",
    false: "No"
  },
  file_input_dialog: {
    title: "File upload",
    introduction:
      "<br/>You can upload two type of files:\
      <ul>\
        <li>OD flow: in CSV or XLSX</li>\
        <li>OD of individuals: in GeoJSON</li>\
      </ul>\
      <br/>\
      To get started, you can use one of these two examples\
      (download to your computer, then import here):\
      <ul>\
        <li><a href='static/data/example_od_flows.xlsx' download='example_od_flows.xlsx'>Example of OD flow</a></li>\
        <li><a href='static/data/example_od_user.geojson' download='example_od_user.geojson'>\
          Example OD of individuals\
        </a></li>\
      </ul>",
    placeholder: "Click here to choose the file(s) to upload"
  },

  about: {
    button: "About",
    description:
      'Kite is a bird of prey with a keen sight that is easily observed in the Pyrenees (montains south of France). Kite was originally developed to visualize agent-based simulator data <a href="https://github.com/tellae/starling" target="_blank"> Starling </a>. Whether it is the input data (synthetic population of trips), and the output data (dynamic traces and KPIs). Kite then evolved to provide a more comprehensive environment for visualizing and analyzing mobility data and transportation systems.'
  },
  kite_layers: {
    types: {
      Undefined: "Undefined",
      Multiple: "Multiple",
      Circle: "Circle",
      Line: "Line",
      OffsetLine: "Offset line",
      Symbol: "Symbol",
      Fill: "Fill",
      Mask: "Mask",
      Heatmap: "Heatmap",
      Geojson: "GeoJSON",
      Scatterplot: "Scatterplot",
      Arc: "Arc",
      FlowMap: "FlowMap"
    },
    layers: {
      "drawn-pt-line": {
        name: "Line drawing"
      },
      "drawn-pt-line-buffer": {
        name: "Buffer of line drawing"
      },
      "isochrone-public-transport": {
        name: "Public transport isochrone"
      },
      "gtfs-stops": {
        name: "Public transport stop points"
      },
      "gtfs-lines": {
        name: "Public transport lines"
      },
      "users-heatmap": {
        name: "OD heatmap"
      },
      "origin-scatterplot": {
        name: "OD origins"
      },
      "destination-scatterplot": {
        name: "OD destinations"
      },
      "users-flowmap": {
        name: "OD flow"
      },
      "users-arclayer": {
        name: "OD arcs",
        origin: "Origin",
        destination: "Destination"
      },
      "users-arclayer-simulation": {
        name: "OD arcs simulation"
      },
      graph: {
        name: "Simulation graph"
      },
      area: {
        name: "Service area"
      },
      staticpoint: {
        name: "Static agents"
      },
      stoppedmovingpoint: {
        name: "Stopped agents"
      },
      movingpoint: {
        name: "Moving agents"
      }
    }
  },
  map_actions: {
    running: "Finish current map action before starting another",
    search: {
      search_location: "Search for location"
    },
    draw: {
      title: "Draw a layer",
      desc: "Draw shapes and save them as a layer.\
      Activate the form button for creating a new form.\
      <ol>\
      <li>Activate the form button for creating a new form</li>\
      <li>Click on the map to draw a form</li>\
      <li>Double-click for ending the drawing</li>\
      </ol>",
      polygon: "Polygon",
      linestring: "Line",
      points: "Points"
    },
    zone_selection: {
      title: "Select spatial entities",
      desc: "Choose a zone to study.\
      <ol>\
      <li>Select an administrative subdivision</li>\
      <li>Click on the map to select one or more entities</li>\
      </ol>"
    },
    point_selection: {
      title: "Point of interest creation",
      map_select: "Geolocalisation",
      zoom: "Zoom on point",
      click: "Click on the map",
      address_select: "Define from postal address",
      address: "Address",
      lng: "Longitude",
      lat: "Latitude"
    },
    map_export: {
      menu_tooltip: "Export map to PNG or PDF",
      title: "Map export",
      formats: {
        pdf_report: "Map with legend in PDF format",
        png_report: "Map with legend in PNG format",
        png_image: "Map only in PNG format"
      },
      layer_select: {
        label: "Layers added to legend",
        no_data: "No layer can be added to the legend",
        placeholder: "No layer selected",
        all: "All",
        layers_selected: "{n} layers selected"
      },
      sources_select: {
        label: "Sources",
        hint: "Choose among known sources or add new ones",
        placeholder: "No source selected",
        search: "No known source matches '{search}'. Press <kbd>Enter</kbd> to create a new one"
      },
      map_title: "Map title",
      default_map_title: "My map"
    }
  },
  map_export: {
    texts: {
      legend_title: "Legend",
      sources: "Sources",
      other: "Other"
    },
    success_message: "Map export in progress"
  },
  add_dialog: {
    tabs: {
      database: "Database",
      file: "File",
      url: "Url",
      draw: "Draw",
      project: "Project",
      admin: "Admin. entities"
    },
    titles: {
      stats: "Statistics",
      name: "Name the added data",
      config: "Import configuration"
    },
    basics: {
      no_data: "Missing data",
      file_input_placeholder: "Select a file",
      multiple_file_input_placeholder: "Select one or more file"
    },
    errors: {
      required: "Missing value",
      name_length_error: "The number of characters must be lesser than {max_size}",
      name_regex: "Forbidden characters",
      add: "An error happened while adding data"
    }
  },
  map_layers: {
    name: "Layers",
    desc: "Map territorial data",
    layer_add: "Layers",
    kite_layers: "Layers list",
    base_layer: "Background",
    opacity: "Opacity",
    maps: "Maps",
    naming: "Name your layer",
    default_name: "My layer",
    tooltips: {
      map_views: "Save or load maps"
    },
    layer_table: {
      headers: {
        drag: "",
        name: "Name",
        type: "Type",
        color: "Color",
        size: "Size",
        display: "Display",
        actions: "Actions",
        theme: "Theme",
        main_dataset_provider: "Source",
        date: "Date"
      },
      no_data: "Please add a new layer",
      display_builtin: "Display all layers",
      hide_builtin: "Hide built in layers",
      errors: {
        loading: "Error while getting full database layers table: please reload the application",
        download: "Error while downloading the layer: {error}",
        minzoom: "Map is not zoomed enough, cannot download layer data"
      }
    },
    add_page: {
      stats: {
        nb_features: "Number of features : ",
        geometry: "Layer's geometry : "
      },
      name_prefix: "My layer ",
      type: "Layer type",
      add_error: "An error occured while adding the layer",
      validation_error: "The data is not a valid GeoJSON"
    },
    layer_from_file: {
      title: "Import a layer from a file",
      description: "Add a custom GeoJSON file",
      multiple_file_error: "Only a single file can be added",
      json_error: "Incorrect JSON format",
      line_info_error: "Expected LineString or MultiLineString geometry"
    },
    layer_from_admin: {
      title: "Import a layer from a selection of administative entities",
      description:
        "Select administrative entities and save them as a layer.\
      <ol>\
      <li>Select the relevant administrative level</li>\
      <li>Click on the entities to select or unselect them</li>\
      <li>Make sure that the entities are loaded by unzooming until they are fully visible</li>\
      </ol>",
      layer_name: "{nb_entities} entities at {level_name} level"
    },
    database_layers: {
      title: "Import layers from database",
      description: "Add layers from database",
      theme_filter: "Theme",
      refresh: "Update with map extent",
      refresh_error: "An error occured while accessing the layer database",
      layer_information: "Open information",
      add_layer: "Add layer",
      viewport_limited_tooltip: "loading only on the current viewport",
      viewport_limit_error: "Current viewport is too large to load this layer on",
      empty_viewport_limited_layer:
        "Imported layer is emmpty. Check that the map viewport is large enough to include all the desired objects.",
      themes: {
        empty: "No theme selected",
        all: "All layers",
        carpooling: "Carpooling",
        demography: "Demography",
        employment: "Employment",
        rail: "Rail",
        schooling: "Schooling",
        travel_generators: "Travel generators",
        mobility: "Mobilities",
        land_use: "Land use",
        income: "Income and living standards",
        public_transport: "Public transport",
        zoning: "Zoning and perimeters",
        car_sharing: "Carsharing",
        bike: "Bike",
        car: "Car",
        walk: "Pedestrian"
      }
    },
    layer_from_url: {
      title: "Import a layer from an URL",
      intro: "Add an URL that points to a GeoJSON",
      example0: "Example: choose a dataset at",
      example1: " and create an export URL from the "
    },
    geometries: {
      LineString: "Line",
      Polygon: "Polygon",
      Point: "Point",
      multiple: "Multiple",
      empty: "Empty data",
      no_data: ""
    }
  },
  map_view: {
    manager: {
      title: "Maps manager",
      tooltip: "Maps are localised and contain various objects to display",
      save_map: "Save current map",
      save_zoom: "Save current zoom",
      tabs: {
        map: "Maps",
        zoom: "Zooms"
      }
    },
    add: {
      title: "",
      tooltip: "Check elements contained in the map. The data describing them will be saved in project.",
      name_new: {
        map: "New map",
        zoom: "New zoom"
      }
    },
    share: {
      copied: "URL copied to clipboard"
    },
    item_types: {
      custom_layer: "Custom layer",
      database_layer: "Database layer",
      flows: "Flows",
      gtfs_network: "GTFS network"
    },
    errors: {
      item_error: {
        load: "An error occured while loading '{name}' ({type})",
        creation: "An error occured while adding '{name}' ({type})"
      },
      create_error: "Error while creating new map",
      access_error:
        "You do not have access to save of {type} data. These data were not added to the map. Contact us by email at {mail} for more information\
        and get an access.",
      share_error: "Error while creating the map share link",
      renaming_error: "Error while renaming map",
      load_error: "Error while getting map data",
      delete_error: "Error while deleting map"
    },
    sources: {
      user: "User data"
    }
  },
  od: {
    flowmap: {
      in: "Incoming",
      out: "Outgoing",
      internal: "Internal",
      click: "Click for showing flows",
      trips: "Counts"
    },
    error: {
      extra: "Too many files selected",
      extension: "Extension is not compatible with the number of files",
      name: "OD flows files must be named 'flows' and 'locations'",
      extra_worksheet: "Too many worksheets in excel file",
      worksheet_name: "One worksheet must start with",
      import: "Please check input file",
      nested: "Require one csv and one nested json",
      agent_id: "agent_id is a required property",
      agent_type: "agent_type is a required property",
      unknown_agent_type: "Unknown agent type"
    }
  },
  flows: {
    name: "Flows",
    flows: "Flows list",
    desc: "Visualise and analyse OD flows (Origin - Destination)",
    type: {
      FLOWMAP: "Flows",
      STARLING: "OD"
    },
    manager: {
      add: {
        button: "Flow",
        tooltip: "Add new flow data",
        stats: {},
        config: {
          type: "Flow type"
        },
        file: {
          title: "Import flows from file",
          intro:
            "<br/>You can upload two type of files:\
            <ul>\
              <li>OD flow: in CSV or XLSX</li>\
              <li>OD of individuals: in GeoJSON</li>\
            </ul>\
            <br/>\
            To get started, you can use one of these two examples\
            (download to your computer, then import here):\
            <ul>\
              <li><a href='static/data/example_od_flows.xlsx' download='example_od_flows.xlsx'>Example of OD flow</a></li>\
              <li><a href='static/data/example_od_user.geojson' download='example_od_user.geojson'>\
                Example OD of individuals\
              </a></li>\
            </ul>"
        },
        project: {
          title: "Import flows from the project",
          placeholder: "Select a flow data"
        }
      },
      table: {
        no_data_text: "No flows to display",
        edit_name: "Edit flows name",
        edit_taken: "This name already exists",
        edit_confirm: "Press Enter to confirm",
        headers: {
          name: "Name",
          type: "Type",
          displayed: "",
          actions: "Actions",
          tools: "Tools"
        },
        tooltip: {
          copy: "Create a copy",
          download: "Download data",
          delete: "Delete",
          aggregate: "Aggregate flows",
          save: "Save",
          summary: "Summary of flows",
          regroup: "Regroup OD"
        }
      }
    },
    filters: {
      title: "Filters",
      selected_location: "Selected node : ",
      flowmap_minimum: "Minimum flow",
      flowmap_minimum_scale: "Scale factor",
      starling_proportion: "Proportion of the random sample (in %)",
      seed: "Seed for random sample"
    },
    plots: {
      title: "Graphics",
      category: "Property",
      interval: "Interval",
      to_numbers: "Switch to values",
      to_percent: "Switch to percent"
    },
    statistics: {
      title: "Statistics"
    },
    database: {
      title: "Import from databases",
      source: {
        text: "Data selection",
        data: "Data",
        zoning: "Zoning",
        date: "Date",
        source: "Source"
      },
      attributes: {
        text: "Attributes selection",
        label: "Attributes"
      },
      spatial: {
        text: "Spatial selection",
        explanation: {
          action: "Click on",
          comment: "for selecting spatial entities"
        },
        zoning: "Administrative subdivision",
        placeholder: "No entity selected",
        join: "Flow selection method",
        operations: {
          intersect: "Intersection",
          intern: "Intern",
          incoming: "Incoming",
          outgoing: "Outgoing",
          extern: "Extern",
          incoming_outgoing: "Incoming + Outgoing"
        }
      },
      count: {
        text: "Other filters",
        label: "Minimum flow"
      },
      description: {
        sources: "Sources",
        process: "Data processing"
      }
    },
    aggregation: {
      title: "Flows aggregation",
      information:
        "Aggregation of individual trips on an administrative subdivision\
        or using a clustering algorithm",
      selection: "Select the aggregation method",
      admin: {
        title: "Administrative subdivision",
        text: "Select the administrative scale of aggregation.",
        name_format: "{original_name} using {zoning} zoning"
      },
      dbscan: {
        title: "Clustering",
        text: "Choose the maximum distance between two points and\
          the minimum number of points to form a cluster.\
          Warning: points with no cluster will be removed from the flows.\
          We recommend to keep the minimum number of points per cluster at 1.",
        distance: "Maximum distance between two points",
        min_samples: "Minimum number of points per cluster",
        rules: {
          min_samples: {
            empty: "Minimum number of points per cluster is required.",
            positive: "Minimum number of points per group must be greater than zero."
          },
          distance: {
            empty: "Maximum distance between two points is required.",
            positive: "Maximum distance between two points must be greater than zero."
          }
        },
        name_format: "{original_name} aggregated using DBSCAN algorithm"
      },
      attributes: {
        title: "Attributes",
        text: "Choose the attributes linked to the origin and the destination.\
          The algorithm groups flows on these attributes.",
        origin: "Origin attribute",
        destination: "Destination attribute",
        name_format: "{original_name} aggregated by attributes"
      },
      "custom-zoning": {
        title: "Custom",
        text: "Aggregating flows using a custom zoning.\
          <br/>\
          <ol>\
            <li>Prepare a zoning of polygons containing attributes <i>id</i> and <i>name</i></li>\
            <li>Upload here this zoning in GeoJSON</li>\
          </ol>\
          <a href='static/data/example_zonage.geojson' download='example_zonage.geojson'>Zoning example</a>\
          <br/>",
        file_input: "GeoJSON zoning file",
        name_format: "{original_name} aggragated using a custom zoning"
      },
      centered: {
        title: "Automatic (beta)",
        information: "Grouping of flows on administrative subdivisions around the selected entity",
        text1: "Select flows base administrative subdivision",
        recommendation: "Recommendation: take the base administrative subdivision of your data",
        text2: "Select the central entity of grouping",
        zoning: "Administrative subdivision",
        location: "Entity"
      },
      select_attributes: "Select individual trips attributes to keep",
      attributes_label: "Attributes"
    },
    summary: {
      title: "Flows summary table",
      headers: {
        origin: "Origin",
        dest: "Destination",
        count: "Count",
        percentage: "Percentage"
      }
    },
    zonings: {
      ind: "Individual",
      iris: "IRIS",
      com: "Municipality",
      epci: "EPCI",
      dep: "Department",
      reg: "Region"
    }
  },
  trace: {
    name: "Trace",
    file: "Import Starling traces",
    analysis: "Analysis",
    iconrules: "Icon Rules",
    error: {
      no_file: "No file found, an error may have been encountered while reading the file",
      multiple_file: "Only one file is expected",
      format: "Only geojson or geojson.gz are accepted"
    },
    analysis_dialog: {
      title: "Trace analysis",
      intro: "Here a displayed some indicators on the uploaded trace.",
      headers: {
        agent_type: "Agent type",
        number: "Number"
      },
      no_data: "Trace cannot be decomposed in agent types"
    },
    paths: {
      button: "Paths",
      title: "Agent paths",
      agent_type: "Agent type",
      agent_id: "Agent id",
      selected_suffix: "agents selected",
      select_all: "Select all",
      layer_name: "Layer name"
    },
    mobile: "Moving objects",
    statics: "Static objects",
    stopped: "Stopped moving objects",
    activity: "Activity related display",
    follow: "Agent follow-up",
    follow_dialog: {
      title: "Agent follow-up",
      selection: "Selection",
      agent_type: "Agent type",
      agent_id: "Agent id",
      agent: "Agent",
      no_data: "No data",
      headers: {
        hour: "Hour",
        status: "Event"
      }
    },
    webgl: "WebGL (test)",
    dialog: {
      intro1: "Import a ",
      intro2: "Starling visualisation file.",
      intro3: "For a better animation experience, we recommend using Chrome browser",
      choose: "Choose the visualisation options",
      interval: "Update interval of moving objects (seconds)",
      users: "Import users",
      static_layer: "Use layer for static objects"
    },
    timeline: {
      parameters: "Parameters",
      run: "Play / pause",
      dialog: {
        title: "Parameters",
        speed: "Animation speed",
        attribute: "Attribute",
        decksize: "Size Web GL"
      }
    },
    icon_rules: {
      title: "Icon rules",
      intro1: "Set a rule on an agent type to change the icons depending on a numeric property.",
      intro2:
        "The rule is applied if the property value is between the range start (included) and the range end (excluded).",
      intro3:
        "To keep the icon specified in the trace, select 'Original'. To keep the default color of the selected icon, leave the color at 'Original'.",
      type: "Agent type",
      property: "Property",
      range: "Range",
      start: "Start",
      end: "End (excluded)",
      icon: "Icon"
    }
  },
  kpi: {
    name: "KPI",
    file: "Import Starling KPI",
    upload: {
      intro1: "Import a KPI file from Starling.",
      intro2: "Analyse the KPIs of your simulation with statistical tools."
    },
    analysis: {
      button: "Analysis",
      title: "KPI analysis",
      intro1: "Number of entries for the displayed KPIs : ",
      intro2:
        "These tables display simple statistical mesures on the given KPIs. Complex KPIs such as ChargeKPI or TransferKPI won't display nicely.",
      numeric_tab: "Numeric KPIs",
      literal_tab: "Class KPIs",
      headers: {
        KPI: "KPI",
        sum: "Sum",
        mean: "Mean",
        sd: "Std Dev.",
        min: "Min",
        max: "Max",
        value: "Value",
        count: "Count",
        percentage: "Percentage"
      },
      no_data: "No KPI available"
    }
  },
  network: {
    name: "Network",
    networks: "Networks list",
    desc: "Analyse transport networks and mobility services",
    tabs: {
      statistics: {
        title: "Statistics"
      },
      variants: {
        title: "Variants",
        headers: {
          start_stop_name: "Start",
          end_stop_name: "End",
          num_stops: "Number of stops",
          num_trips: "Number of trips"
        }
      },
      cover: {
        title: "Cover"
      }
    },
    info_dialog: {
      title: "Network analysis"
    },
    stats_labels: {
      num_routes: "# routes",
      num_trips: "# trips",
      num_variants: "# variants",
      num_stops: "# stop points",
      num_estimated_vehicles: "# vehicles (estimation)",
      route_name: "Route",
      route_type: "Type",
      is_bidirectional: "Bidirectional",
      start_time: "Start time",
      end_time: "End time",
      mean_trip_distance: "Mean trip distance",
      mean_trip_duration: "Mean trip duration",
      min_headway: "Minimum headway",
      max_headway: "Maximum headway",
      mean_headway: "Mean headway",
      service_distance: "Service distance",
      service_duration: "Service duration",
      service_speed: "Mean service speed",
      num_trip_starts: "# started trips",
      num_trip_ends: "# ended trips",
      peak_start_time: "Start of peak time",
      peak_end_time: "End of peak time",
      peak_num_trips: "# peak time trips"
    },
    table: {
      routes: "Routes",
      stops: "Stops"
    },
    add: {
      button: "PT network",
      tooltip: "Add a public transport network",
      database: {
        title: "Select a network from the Tellae database",
        network: "Transport network",
        dates: "Dates"
      },
      project: {
        title: "Select a network",
        intro:
          "We use GTFS format for Public transport networks.\
          You can find such files on this website\
          <a href='https://transport.data.gouv.fr/datasets?q=gtfs' target='_blank'>transport.gouv.fr</a>\
          or using this <a href='static/data/example_gtfs.zip' download='example_gtfs.zip'>example</a>",
        placeholder: "Select a GTFS file",
        upload: "Import a GTFS file"
      },
      config: {
        has_all_stops: "Show only the stops served for the selected day"
      }
    },
    gtfs: {
      error_loading: "Error while loading network data",
      title: "Explore a public transport network",
      introduction:
        "For importing a GTFS file, you have to:\
        <ol>\
          <li>Download a GTFS file on your computer\
          (for example <a href='static/data/example_gtfs.zip' download='example_gtfs.zip'>this one</a>,\
          or from <a href='https://transport.data.gouv.fr/datasets?q=gtfs' target='_blank'>transport.gouv.fr</a>)</li>\
          <li>Upload your file by opening your project configuration.\
          Then click on your project name (at the top right of the page).\
          And finally, go to <i>NETWORKS</i>.</li>\
          <li>If your file doesn't appear in the list below, please reload KITE with F5.</li>\
        </ol>",
      file: "GTFS file",
      has_all_stops: "Show only the stops served for the selected day"
    },
    draw_pt_line: {
      short_title: "New PT line",
      title: "Draw a new transport line",
      desc: "Draw a transport line or import a GeoJSON file.\
      Analyse data on the line environment.",
      draw: "Draw",
      import: "Import a trace",
      export_trace: "Export",
      tab: {
        statistics: "Statistics",
        costs: "Costs"
      },
      cost_model: {
        length: "Length (m)",
        round_trip: "Number of round trips",
        activation: "Activation rate (%)",
        cost_km: "Operation costs €/km",
        result: "Operation costs are estimated to",
        result_unit: "€ / an",
        periods: "Périods"
      },
      data: {
        distance: "Distance around the line (m)",
        desc: "Indicators at {buffer} m around the line",
        distance_text: "Length of the line:",
        itinerary: {
          label: "Itinerary",
          length: "Length (m)"
        },
        poptot: {
          label: "Population",
          ind: "Total",
          Ind_6_10: "6 to 10 years",
          Ind_11_17: "11 to 17 years",
          Ind_18_24: "18 to 24 years",
          Ind_25_39: "25 to 39 years",
          Ind_40_54: "40 to 54 years",
          Ind_55_64: "55 to 64 years",
          Ind_65_79: "65 to 79 years",
          Ind_80p: "80 years and more"
        },
        comp: {
          label: "Companies",
          "1_2": "1 to 2 employees",
          "3_5": "3 to 5 employees",
          "6_9": "6 to 9 employees",
          "10_19": "10 to 19 employees",
          "20_49": "20 to 49 employees",
          "50_99": "50 to 99 employees",
          "100_199": "100 to 199 employees",
          "200_249": "200 to 249 employees",
          "250_499": "250 to 499 employees",
          "500_999": "500 to 999 employees",
          "1000_1999": "1000 to 1999 employees",
          "2000_4999": "2000 to 4999 employees",
          "5000_9999": "5000 to 9999 employees",
          "10000_+": "10000 employees and more",
          total: "Total"
        },
        sch: {
          label: "Education",
          elem_schools: "Schools (counts)",
          mid_schools: "Secondary school (counts)",
          high_schools: "High school (counts)",
          elem_schools_numbers: "Schools (numbers)",
          mid_schools_numbers: "Secondary school (numbers)",
          high_schools_numbers: "High school (numbers)"
        },
        stat: {
          label: "Stations",
          stations: "Number of railway stations",
          travelers: "Annual stations attendance"
        }
      }
    },
    dialog_variants: {
      title: "Select variants",
      headers: {
        direction: "Direction",
        start_stop_name: "Start",
        end_stop_name: "End",
        num_stops: "# Stops",
        num_trips: "# Trips",
        selected: "Selected"
      }
    },
    pt_isochrones: {
      title: "Evaluate public transport isochrones",
      stop: "Stop",
      start_time: "Departure time",
      buffer_time: "Maximum walking time",
      suffix: "minute(s)"
    },
    road_isochrones: {
      button: "Isochrones",
      tooltip: "Compute isochones for car, pedestrian and bicycle",
      title: "Compute an isochrone",
      point: "Choose the start/end point",
      max_travel_time: "Max travel time",
      layer_name: "{max_time} min isochrone, {mode}"
    }
  },
  statistics: {
    title: "Statistics",
    tabs: {
      network: "Network"
    },
    flows: {
      volume: "# trips before filter",
      volume_filtered: "# trips",
      perc_filtered: "% of trips retained",
      pairs: "# OD pairs",
      average_distance: "Average distance (km)"
    },
    network: {
      tabs: {
        network: "Network",
        route: "Route",
        advanced: "Advanced"
      },
      headers: {
        name: "Data",
        data: "Value"
      },
      more: "More"
    }
  },
  plots: {
    title: "Graphics",
    tabs: {
      network: "Network"
    },
    network: {
      line: "Line",
      start: "Start",
      end: "End",
      type: {
        theoretical_travel_time: "Travel time",
        headway: "Headway"
      },
      travel_time_historic: "Weekday real",
      series: {
        theoretical_travel_time: "Theoretical travel time",
        headway: "Theoretical headway",
        real_travel_time: "Real travel time"
      },
      update: "Update",
      historic: "Loading"
    }
  },
  props_mapping: {
    paint_types: {
      color: "Color",
      size: "Size",
      opacity: "Opacity",
      tooltip: "Tooltip"
    },
    generic: {
      coefficient: "Multiplicand",
      default: "Other",
      unit: "Unit : ",
      change_input_mode: "Change input mode"
    },
    layer_specifics: {
      isochrones: "Stop <b>{stop}</b><br />on {date} at {hour}"
    },
    constant: {
      name: "Constant value"
    },
    direct: {
      name: "Value from property",
      select_label: "Property defining the value"
    },
    category: {
      name: "Value by category"
    },
    continuous: {
      name: "Value by interval",
      less_than: "Less than ",
      to: " to ",
      and_more: " and more"
    },
    tooltip: {
      select_label: "Properties displayed in the tooltip",
      select_placeholder: "None",
      select_no_data: "No properties found"
    },
    errors: {
      not_available: "Not available yet",
      no_editable_attribute: "No editable attribute found for this layer"
    }
  },
  ux_display: {
    legends: "Legends",
    animationCommands: "Animation controls",
    showNavigationPanel: "Show navigation panel",
    hideNavigationPanel: "Hide navigation panel"
  },
  simulations: {
    name: "Simulation",
    simulations: "Scenarios list",
    desc: "Design and evaluate scenarios of mobility services",
    pages: {
      model_selection: "Mobility service selection",
      model_scenarios: "Scenario list ({service_name})",
      use_case_selection: "Use case selection ({service_name})",
      scenario_creation: "Scenario creation ({service_name})"
    },
    tooltip_add: "New scenario",
    tooltip_analysis: "New analysis",
    analysis: "Scenario analysis",
    new_scenario: "New scenario created",
    successfull_creation: "Simulation scenario successfully created",
    error_use_case_param: "Error while processing the use case parameters",
    launch_failed: "Scenario launch failed with error",
    data: {
      error_trace: "Error while fetching scenario trace",
      error_graph: "Error while fetching scenario graph",
      error_users: "Error while fetching scenario users",
      error_download: "Error while trying to download scenario files"
    },
    stepper: {
      step: {
        scenario: "Use case : {use_case}",
        environment: "Localisation",
        demand: "Travel demand",
        offer: "Mobility service",
        validation: "Summary of parameters",
        info: "Description"
      },
      buttons: {
        next: "Next",
        previous: "Previous",
        run_simulation: "Run simulation"
      },
      rules: {
        comment: {
          length: "Text cannot exceed {max_length} caracters"
        },
        penetration_rate: {
          empty: "The penetration rate is required",
          range: "The penetration rate must be between 0% and 100%"
        }
      },
      labels: {
        scenario_name: "Scenario name",
        comment: "Comment",
        options: "Advanced options",
        demand_file: "Demand file",
        penetration_rate: "Penetration rate (%)",
        random_seed: "Random seed",
        number_vehicles: "Number of vehicles",
        capacity: "Number of seats / veh.",
        max_waiting_time: "Pickup window",
        undefined: "Undefined"
      },
      headers: {
        text: "Parameter",
        value: "Value"
      }
    },
    speeds: {
      title: "Speeds choice",
      tooltip: "Define the traffic speeds of the vehicles in the simulation",
      configure: "Configure speeds"
    },
    models: {
      SB_VS: {
        title: "Station-based vehicle sharing"
      },
      FF_VS: {
        title: "Free-floating vehicle sharing"
      },
      TT_PT: {
        title: "Public transport network"
      },
      ODT: {
        title: "On-demand transport"
      },
      CP: {
        title: "Carpooling"
      },
      BUSARD: {
        title: "Bus route"
      }
      // SB_VS_R: {
      //   title: "Station-based vehicle sharing with repositioning"
      // },
      // OD_P_T: {
      //   title: "On-demand and timetabled public transport"
      // }
    },
    use_cases: {
      ODT: {
        odt_railway_station: {
          description: {
            scenario:
              "The use case concerns an on-demand transport service to a station of the railway network.\
              The simulation is suitable for a suburban or rural station.\
              The perimeter of the service is delimited by a circular area around the station.\
              You choose the size of your service. You also choose\
              the penetration rate of the service on the potential feeder demand.",
            environment:
              "The service area is delimited by a circular area around the selected station.\
              <br/>Please select the station and set the circular area.",
            demand:
              "The penetration rate allows you to estimate the volume of demand captured by\
              the demand-response service.",
            demand_options:
              "The random seed allows you to modify the random draws\
              (request received, departure times and origins / destinations). Change the random seed\
              to test the robustness of the service.",
            offer:
              "The vehicles all have the same number of seats. Quality of\
              service is determined by the maximum waiting time users of the service."
          },
          title: "Railway station feeder line",
          labels: {
            railway_station: "Railway station",
            radius: "Circular area radius"
          },
          rules: {},
          suffix: {
            radius: "meters"
          },
          tooltip: {
            update_station: "Update with map area"
          }
        },
        odt_basic: {
          description: {
            scenario: "Simulate a common on-demand transport based on your estimate of users requests.",
            environment:
              "The service area is computed automatically.<br/>\
              Please configure vehicle speeds for each road type.",
            demand: "Choose a demand file previously imported into your project (see FLOW).",
            offer:
              "Vehicles all have the same number of seats. Quality of\
              service is determined by the pickup window.<br/>\
              Please configure vehicles."
          },
          title: "Common on-demand transport",
          labels: {
            depot: "Depot localisation"
          }
        },
        odt_reduced_mobility: {
          title: "Door to door Service for persons with reduced mobility"
        },
        odt_rural_area: {
          title: "New zonal service in rural area"
        },
        odt_replace_bus: {
          title: "Regular bus route replacement"
        }
      },
      FF_VS: {
        ff_vs_new_service: {
          description: {
            scenario:
              "The use case is about creating a new free-floating service.\
              After selecting the deployment area, you can choose the number\
              of bikes and their location. You also choose the penetration rate\
              of the service on the potential demand.",
            environment:
              "The free-floating service is deployed within the perimeter of\
              the selected municipality. Only French cities of Loire-Atlantique\
              and Ille-et-Vilaine are available for simulation.",
            demand:
              "The penetration rate allows you to estimate the\
              volume of demand captured by the service. The rate applies\
              to the demand for trips internal of the selected municipality\
              and of more than 1km.",
            offer:
              "You can change the number of bikes, as well as their\
              location at the start of the day.\
              <ul>\
              <li>Random: random generation of bikes</li>\
              <li>Grid: following a grid</li>\
              <li>Density: weighted by population density</li>\
              </ul>"
          },
          title: "New free-floating service",
          labels: {
            commune: "Municipality",
            number_bikes: "Number of bikes",
            location: "Location"
          },
          rules: {},
          no_data_text: {
            commune: "No municipality found"
          },
          locations: {
            random: "Random",
            grid: "Grid",
            density: "Density"
          },
          tooltip: {
            update_communes: "Update with map area"
          }
        },
        ff_vs_competition: {
          title: "New free-floating service in a specific area (university campus, business area, etc)"
        },
        ff_vs_pricing: {
          title: "Pricing strategy optimization"
        },
        ff_vs_relocation: {
          code: "ff_vs_relocation",
          title: "Optimization of rebalancing and recharge operations"
        }
      },
      TT_PT: {
        tt_pt_delete_bus_routes: {
          description: {
            scenario: "The use case concerns the impact of the closure of one or more lines.",
            environment: "The simulation is delimited by the perimeter of the selected <b>network</b>.",
            demand:
              "The <b>penetration rate</b> allows you to estimate the volume of demand captured by the public transport service.",
            offer: "The selected <b>routes</b> are removed from the public transport network."
          },
          title: "Closing of one or more routes",
          labels: {
            metropolis: "Public transport network",
            closed_routes: "Closed route(s)"
          },
          rules: {},
          placeholders: {
            closed_routes: "No closed route"
          }
        },
        tt_pt_add_new_route: {
          title: "Opening of a new route"
        },
        tt_pt_timetable: {
          title: "Network restructuring"
        },
        tt_pt_perturbation: {
          title: "Development of intermodality"
        }
      },
      SB_VS: {
        sb_vs_service_reorganization: {
          description: {
            scenario:
              "The use case concerns the changes of an existing bike-sharing service\
              with stations. Without changing the number of stations, you can adapt\
              the number of bikes. You also choose the location of the bikes at the\
              start of the day and whether you want to set up a rebalancing during\
              the day or not. Finally, you can simulate an evolution of the volume\
              of requests captured by the service.",
            environment: "The simulation applies to the selected service and date.",
            demand:
              "The percentage of demand applies to the current demand. This allows\
              you to reduce (less than 100%) or increase the request volume (greater\
              than 100%).",
            offer:
              "You can modify the number of bikes (the given number is the number of\
              bikes for the selected day), as well as their location at the\
              start of the day. Daytime rebalancing can be enabled or disabled.\
              <ul>\
              <li>Historic: use bikes location of the selected day</li>\
              <li>Uniform: each station has the same filling rate</li>\
              <li>Weighted by population: the number of bikes depends on the population around the station</li>\
              <ul>"
          },
          title: "Changes in existing service",
          labels: {
            city: "Service",
            // day_type: "Day type",
            date: "Date",
            demand_percentage: "Demand percentage (%)",
            number_bikes: "Number of bikes",
            location: "Location",
            relocation: "Rebalancing"
          },
          rules: {
            // day_type: {
            //   empty: "A type of day must be selected"
            // },
            location: { empty: "A location must be chosen" },
            demand_percentage: {
              range: "The demand percentage must be between 0% and 1000%"
            }
          },
          locations: {
            historic: "Historic",
            uniform: "Uniform",
            weighted_by_population: "Weighted by population"
          }
          // day_types: {
          //   LAVHV: "Working days excluding holidays",
          //   SAMHV: "Saturdays except holidays",
          //   DIMFHV: "Sundays and public holidays excluding holidays",
          //   LAVVAC: "Holiday working days",
          //   SAMVAC: "Holiday Saturdays",
          //   DIMFVAC: "Holiday Sundays and public holidays"
          // },
        },
        sb_vs_carsharing_new: {
          title: "Creation of a new car-sharing service"
        },
        sb_vs_new_service: {
          title: "Create a new service"
        },
        sb_vs_relocation: {
          title: "Optimization of rebalancing operations"
        }
      }
    },
    scenario_list: {
      empty: "No scenario",
      error: "Error: unable to list scenarios",
      items_per_page: "Scenarios per page",
      of: "of",
      confirm_delete: "Do you really want to delete the scenario ",
      date: "Created the {date} at {hour}",
      actions: {
        download: "Download",
        od: "Flows",
        run: "Simulation",
        graph: "Graph",
        kpis: "Indicators"
      },
      local_export_title: "Export of a simulation run locally",
      status: {
        QUEUED: "In preparation",
        STARTING: "Creation in progress",
        RUNNING: "Running in progress",
        SUCCESS: "Finished",
        ERROR: "Error",
        undefined: "Unknown status"
      }
    },
    kpis: {
      no_kpi_available: "No KPI available for this model",
      tab_title: "Analysis",
      title: "Scenario analysis",
      add_scenario: "Add a scenario",
      caption: "Caption",
      headers: {
        generic: {
          scenario: ["Scenario", "Scenario name"],
          actions: ["Actions", "Available actions"],
          number_users: ["Simulated demand", "Total simulated demand"],
          number_vehicles: ["# Vehicles", "Number of vehicles in the simulation"],
          mean_walk_time: ["Walk time (min)", "Mean walking time of users in minutes"],
          mean_vehicle_time: ["In-vehicle time (min)", "Mean in-vehicle time of users in minutes"],
          mean_wait_time: ["Wait time (min)", "Mean waiting waiting time of users in minutes"],
          mean_total_time: ["Total time (min)", "Mean total time of users in minutes"]
        },
        ODT: {
          journeys: ["Satisfied demand", "Number of satisfied trips by the mobility service"],
          success: ["% Success", "Proportion of satisfied demand compared to simulated demand"],
          KT: ["KT", "Total vehicles kilometers"],
          KCC: ["KCC", "Vehicles kilometers with at least one passenger"],
          HLP: ["% Empty K", "Vehicle empty kilometers proportion (no passenger)"],
          VK: ["V/K", "Number of trips per vehicle kilometers"],
          vehicles: ["Vehicles", "Number of used vehicles"],
          courses: [
            "Runs",
            "Number of runs (a run is a series of trips bounded by a vehicle break\
              whithout any passenger)"
          ],
          bulking: ["Bulking", "Bulking (average number of trips per run)"]
        },
        SB_VS: {
          number_stations: ["Stations", "Number of stations"],
          number_fails: ["Request fails", "Number of fails when trying to get/give back a vehicle"],
          empty_time_stations: ["Empty time", "Empty time of the stations"],
          full_time_stations: ["Full time", "Full time of the stations"],
          staff_drive_distance: [
            "Repositionning distance",
            "Number of kilometers traveled by the repositionning vehicle"
          ]
        },
        FF_VS: {
          mean_utilization_rate: [
            "Vehicle use rate",
            "Mean use rate of the vehicles (Number of trips / Number of vehicles)"
          ],
          mean_utilization_time: [
            "Mean use time",
            "Mean use time of the vehicles (Total time spent in vehicles / Number of vehicles)"
          ],
          number_unused_vehicles: ["Unused vehicles", "Number of unused vehicles"]
        },
        TT_PT: {
          kcc: ["KCC", "Total commercial kilometers traveled by the vehicles"],
          service_time: ["Service time", "Total service time of the vehicles, in minutes (without buffer times)"]
        }
      }
    },
    demo_tad: {
      button: "ODT Demo",
      title: "New ODT simulation (Starling ",
      scenario_name: "Scenario name",
      scenario_name_placeholder: "Name your simulation scenario",
      environment: {
        title: "Environment",
        city: "City",
        gtfs: "GTFS timetables",
        date: "Date"
      },
      service: {
        title: "Service design",
        area: "Area",
        connections: "Connection points",
        connections_hint: "Coma separated stop ids",
        tw: "Time window (minutes)",
        detour: "Max detour (multiplier of direct travel time)",
        vehicles: "Vehicles"
      },
      agents: {
        title: "Scenario agents",
        users: "Users",
        no_data: "Add a synthetic population in the Flows menu"
      },
      validation_message: "Your scenario was successfully created and is now running"
    },
    buttons: {
      scenarios: "Scenarios"
    }
  },
  osm: {
    highway: {
      residential: "Residential",
      secondary: "Secondary",
      primary: "Primary",
      trunk: "Trunk",
      tertiary: "Tertiary",
      living_street: "Living street",
      motorway: "Motorway",
      other: "Other"
    }
  },
  presets: {
    jwt_login_error:
      "An error occured while reading the provided URL. Check for errors or reach the person that shared it with you.",
    type_error: "An error occured while loading : unknown data type.",
    map_view: {
      load_error:
        "An error occured while fetching the map view. Reach the person that shared it with you and check it has not been deleted."
    }
  },
  validation: {
    mandatory: "This value is mandatory",
    strictly_positive: "This value must be strictly positive",
    is_number: "This value must be a number",
    is_between_0_and_1: "This value must be between 0 and 1",
    non_empty_array: "At least one value is expected"
  },
  errors: {
    api: {
      401: "Please login",
      403: "The account being accessed does not have sufficient permissions to access this feature",
      404: "This service does not exist",
      413: "The size of the request body exceeds the maximum size permitted",
      504: "The server is not available",
      server_error: "The server encountered an error",
      unknown_error: "Unknown error"
    },
    feature_access:
      "You do not have access to this feature.\
    Contact us by email at {mail} for more information\
    and get an access",
    mapbox: {
      generic: "The mapping utility encountered an error: {message}",
      layer: "The layer '{layer}' encountered an error: {message}"
    }
  },
  warnings: {
    screen_size_small:
      "Your screen is very small. We do not recommend using KITE with such a small screen. However, you can continue browsing.",
    screen_size_medium: "Your screen is small !",
    burger_recommandation:
      "We recommend using the button with the three horizontal bars at top left to hide or show the navigation panel."
  },
  mySettings: {
    title: "My settings",
    firstName: "First name",
    lastName: "Last name",
    jobTitle: "Job title",
    save: "Save"
  },
  project: {
    title: "My projects",
    shared_projects: "Shared projects",
    new: "Create new project",
    save: "Save",
    cancelSave: "Cancel",
    default_name: "My project",
    settings: {
      title: "Settings",
      data: {
        title: "Data",
        types: {
          spatial_data: "Donnée spatiale",
          flows: "Flux",
          gtfs: "GTFS"
        },
        headers: {
          name: "Name",
          data_type: "Data type",
          actions: "Actions"
        },
        delete_confirmation: "Do you really want to delete the data '{name}' from the project ?"
      },
      share: {
        title: "Share",
        email: "Email to invite",
        invite: "Invite"
      },
      info: {
        title: "Properties",
        name: "Project name",
        description: "Project description"
      }
    },
    selector: {
      label: "Select a project data",
      import: "Import a new data"
    },
    binary_already_added: "This data has already been added to the project under the name {name}",
    errors: {
      get_binary: "Error while trying to get project binary info"
    }
  },
  tools: {
    title: "Toolbox",
    tooltip: "Toolbox"
  },
  territory: {
    title: "Territory",
    menu: {
      tooltip: "See area indicators"
    },
    dialog: {
      title: "Area indicators",
      search: "Search in table",
      agg_level: "Aggregation level",
      table_comment: "Data source INSEE, 2021, unless otherwise stated",
      main_epci: "Main EPCI",
      additional_epci: "Add EPCI to compare",
      additional_epci_button: "Validate",
      epci_table_title: "Main EPCI and compared ones",
      communes_table_title: "Communes of the main EPCI",
      headers: {
        code: "Code",
        city: "City",
        cityGroup: "EPCI",
        population: "Population",
        population_previous: "Population 2015",
        population_growth: "Annual growth of population 2021/2015 (%)",
        density_population: "Population density (inhab/km²)",
        occupied_worker: "Occupied workers 15-64 yrs (%)",
        non_occupied_worker: "Non occupied workers 15-64 yrs (%)",
        less15yrs: "15 yrs or less (%)",
        more64yrs: "64 yrs or more (%)",
        employment: "Employment",
        employment_previous: "Employment 2015",
        employment_growth: "Annual growth of employment 2021/2015 (%)",
        employment_rate_worker: "Employment rate per worker 15-64 yrs (%)",
        worker_in_zone: "Occupied worker 15-64 yrs working in the city (%)",
        unemployment: "Unemployment 15-64 yrs (%)",
        poverty: "Poverty (%)",
        modeshare_dt_pt: "Mode share PT - work (%)",
        modeshare_dt_bike: "Mode share bike - work (%)",
        modeshare_dt_car: "Mode share car - work (%)",
        modeshare_dt_walk: "Mode share walk - work (%)",
        companies10p: "Companies of 10 employees or more in 2024",
        companies50p: "Companies of 50 employees or more in 2024",
        household: "Households",
        household_size: "Households size",
        household_park: "Households having at least one parking (%)",
        household_0car: "Households without any car (%)",
        household_1car: "Households with 1 car (%)",
        household_2pcar: "Households with 2 car or moreutils/territory_information (%)"
      },
      captions: {
        code: "INSEE code",
        city: "City name",
        cityGroup: "EPCI name",
        population: "Total of population",
        population_previous: "Total of population in 2015",
        population_growth: "Average annual growth of total population between 2021 and 2015 in %",
        density_population: "Total population density (inhab/km²)",
        occupied_worker: "Total number of occupied workers aged 15-64, in percentage compared to total population",
        non_occupied_worker:
          "Total number of non occupied workers of 15-64 years, in percentage compared to total population",
        less15yrs: "Population aged 15 or less, in percentage compared to total population",
        more64yrs: "Population aged 64 or more, in percentage compared to total population",
        employment: "Total number of employment",
        employment_previous: "Total number of employment in 2015",
        employment_growth: "Average annual growth of total employment between 2021 and 2015 in %",
        employment_rate_worker: "Number of worker aged 15-64 divided by total number of employment in %",
        worker_in_zone:
          "Proportion of employed workers aged 15-64 whose usual place of work is located in the municipality of residence",
        unemployment: "Number of unemployed workers aged 15-64 divided by the total number of workers aged 15-64",
        poverty:
          "Proportion of households for which the standard of living is below the threshold of 60% of the median standard of living",
        modeshare_dt_pt: "Mode share public transport for the home-work purpose in percentage",
        modeshare_dt_bike: "Mode share bike for the home-work purpose in percentage",
        modeshare_dt_car: "Mode share car for the home-work purpose in percentage",
        modeshare_dt_walk: "Mode share walk for the home-work purpose in percentage",
        companies10p: "Number of establishments of 10 employees or more in 2024 (SIRENE data)",
        companies50p: "Number of establishments of 50 employees or more in 2024 (SIRENE data)",
        household: "Total number of households",
        household_size: "Average household size in number of people",
        household_park: "Proportion of households with at least one car parking space",
        household_0car: "Proportion of households without any car",
        household_1car: "Proportion of households with 1 car",
        household_2pcar: "Proportion of households with 2 car or more"
      }
    }
  }
};
